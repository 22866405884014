<template>
  <div
    class="ProjectsList"
    :style="
      modalCreateProject.opened || modalCreatedResult
        ? 'overflow: hidden; max-height: 100svh;'
        : ''
    "
  >
    <div class="BWPContainer">
      <Navbar style="z-index: 555" />

      <div class="PLContainer">
        <div class="ButtonsContainer">
          <OUIButtonTertary
            class="BackButton"
            ButtonText="Назад"
            :SendCallback="goBack"
            :size="'medium'"
          >
            <template v-slot:LeftIcon>
              <div class="BackImage" />
            </template>
          </OUIButtonTertary>

          <OUIButtonTertary
            v-if="showSkipButton"
            class="BackButton"
            ButtonText="Пропустить шаг"
            :SendCallback="goToProfile"
            :size="'medium'"
          />
        </div>

        <div class="PLCHead">
          <span class="PLCHTitle TextWrapper" lang="ru">Выберите проект</span>
          <span class="PLCHDesc TextWrapper" lang="ru"
            >Это необходимо для выбора задачи, которую вы будете выполнять
          </span>
        </div>

        <OUISearchButtons
          searchPlaceholder="Поиск по проектам"
          :searchInput="searchModel"
          @update:searchInput="updateSearchInput"
          @update:filters="updateSecondFiltersList"
          :buttons="filters"
        />

        <div class="PLCList noselect">
          <div class="lds-dual-ring" v-show="projectsLoading"></div>
          <div class="lds-dual-ring-wrapper" v-show="projectsLoading"></div>

          <div
            v-show="userHasAttempts && pageSelected === 1"
            class="LCard"
            :style="`cursor: default; ${projectsLoading ? 'filter: blur(8px);' : ''}`"
          >
            <span class="CardTitle TextWrapper" lang="ru"
              >Нейросеть поможет определиться какие компетенции вам подходят</span
            >
            <div class="LCBottom">
              <router-link to="/beta/cg/chat-page">
                <OUIButtonPrimary :size="'medium'" ButtonText="Пройти тестирование" />
              </router-link>
            </div>
          </div>

          <div
            v-for="(pc, pcindex) in showCardsList"
            :key="`PC-${pcindex}-${pc.project_type}-${pc.id}`"
            class="LCard"
            :style="`${pc.name !== 'Свой проект' ? '' : 'cursor: default;'} ${
              projectsLoading ? 'filter: blur(8px);' : ''
            }`"
            @click="
              () => {
                if (pc.name !== 'Свой проект') {
                  selectProject(pc.project_type, pc.id);
                }
              }
            "
          >
            <span class="CardTitle TextWrapper" lang="ru">{{ pc.name }}</span>

            <div class="LCBottom">
              <template v-if="pc.name !== 'Свой проект'">
                <div class="InfoBlock">
                  <span class="IBText TextWrapper">{{
                    `${pc.Jobs.length} ${TextWrapperTasksCounter(pc.Jobs.length)}`
                  }}</span>
                </div>

                <div class="InfoBlock">
                  <span class="IBText TextWrapper">{{
                    `${GetProjectTasksDirectionsCounter(pc.Jobs)}`
                  }}</span>
                </div>
              </template>

              <template v-else>
                <OUIButtonSecondary
                  :size="'medium'"
                  @click.native="
                    () => {
                      modalCreateProject.projectId = pc.id;
                      modalCreateProject.opened = true;
                    }
                  "
                  ButtonText="Создать проект"
                />
              </template>
            </div>
          </div>
        </div>

        <OUIPagination
          :key="`PaginationKey-${getProjectsSorted.length}-${userHasAttempts}`"
          :dataCounter="
            userHasAttempts ? getProjectsSorted.length + 1 : getProjectsSorted.length
          "
          :max_items="pageMaxItems"
          :startPage="pageSelected"
          @update:page="updateSelectedPage"
        />
      </div>

      <div class="BGCollections">
        <div class="BGLight"></div>
      </div>
    </div>

    <div
      v-if="modalCreateProject.opened"
      @click.self="modalCreateProject.opened = false"
      class="TaskSelectWrapper"
    >
      <div class="TSModal">
        <div class="TSMHeader">
          <div class="CDifficult">
            <span class="CDText TextWrapper">Создание проекта</span>
          </div>
          <div @click="modalCreateProject.opened = false" class="CloseModal"></div>
        </div>

        <input
          v-model="modalCreateProject.name"
          class="TSMInput"
          type="text"
          placeholder="Коротко опиши суть проекта"
        />
        <div
          class="QEditor QEditorFixHeight QEditorWhitePlaceholder FixedHeaderGap"
          style="min-width: 0; width: 450px; max-width: 100%; min-height: 250px"
        >
          <QuillEditor
            class="QEditorInside"
            toolbar="full"
            ref="QEditorDescription"
            :modules="modulesQuill"
            contentType="html"
            theme="snow"
            placeholder="Подробно, шаг за шагом опиши проект, а также стек планируемых технологий"
            :content="modalCreateProject.description"
            @textChange="
              handleEditorEditChanges($event, 'Description', 'QEditorDescription')
            "
            @ready="handleEditorEditReady('Description', 'QEditorDescription')"
          />
        </div>

        <div
          class="QEditor QEditorFixHeight QEditorWhitePlaceholder FixedHeaderGap"
          style="min-width: 0; width: 450px; max-width: 100%; min-height: 250px"
        >
          <QuillEditor
            class="QEditorInside"
            toolbar="full"
            ref="QEditorResult"
            :modules="modulesQuill"
            contentType="html"
            theme="snow"
            placeholder="Опиши желаемый результат"
            :content="modalCreateProject.result"
            @textChange="handleEditorEditChanges($event, 'Result', 'QEditorResult')"
            @ready="handleEditorEditReady('Result', 'QEditorResult')"
          />
        </div>

        <div
          class="QEditor QEditorFixHeight QEditorWhitePlaceholder FixedHeaderGap"
          style="min-width: 0; width: 450px; max-width: 100%; min-height: 250px"
        >
          <QuillEditor
            class="QEditorInside"
            toolbar="full"
            ref="QEditorEffect"
            :modules="modulesQuill"
            contentType="html"
            theme="snow"
            placeholder="Опиши ожидаемый эффект от реализации проекта"
            :content="modalCreateProject.effect"
            @textChange="handleEditorEditChanges($event, 'Effect', 'QEditorEffect')"
            @ready="handleEditorEditReady('Effect', 'QEditorEffect')"
          />
        </div>

        <div class="Buttons">
          <OUIButtonPrimary
            class="PrimaryReStyle"
            :size="'medium'"
            ButtonText="Отправить на проверку"
            :isActive="canCreateSelfTask"
            :SendCallback="createSelfTask"
          />
          <OUIButtonTertary
            :size="'medium'"
            @click="closeCreationModal"
            ButtonText="Назад"
          />
        </div>
      </div>
    </div>

    <div
      v-if="modalCreatedResult"
      @click.self="modalCreatedResult = false"
      class="TaskSelectWrapper"
    >
      <div class="TSModal">
        <div class="TSMHeader">
          <div class="CDifficult">
            <span class="CDText TextWrapper">Твой проект готов!</span>
          </div>
          <div @click="modalCreatedResult = false" class="CloseModal"></div>
        </div>

        <span class="FinalModalText">
          Проект "{{ this.modalCreateProject.name }}" отправлен на модерацию. О дальнейшем
          статусе проекта мы проинформируем тебя в почте и профиле.
        </span>

        <div class="Buttons">
          <router-link to="/beta/profile">
            <OUIButtonPrimary
              :size="'medium'"
              class="PrimaryReStyle"
              ButtonText="Перейти в профиль"
            />
          </router-link>
          <OUIButtonTertary
            :size="'medium'"
            @click="modalCreatedResult = false"
            ButtonText="Я ещё подумаю"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarCombined.vue";
import OUIButtonPrimary from "@/components/OctaUI/Buttons/primary.vue";
import OUIButtonSecondary from "@/components/OctaUI/Buttons/secondary.vue";
import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";
import OUISearchButtons from "@/components/OctaUI/Filters/SearchButtons.vue";
import OUIPagination from "@/components/OctaUI/Pagination.vue";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

import { getSectorsWithSections } from "@/api/competence.js";
import { getApprovedProjectsWithTasks } from "@/api/n_project.js";
import { getAllProject } from "@/api/project.js";
import { userHasAttempts } from "@/api/career_guidance.js";
import { pinTask, createJob, findAllJobByUser, getUserPinnedTask } from "@/api/job.js";
import { mapGetters } from "vuex";

import Fuse from "fuse.js";

export default {
  name: "RegistrationProjectsList",
  components: {
    Navbar,
    OUIButtonPrimary,
    OUIButtonSecondary,
    OUIButtonTertary,
    OUISearchButtons,
    OUIPagination,
    QuillEditor,
  },
  data() {
    return {
      filters: {
        directions: {
          name: "Направление",
          list: [],
          selected: [],
          code: "directions",
        },
        subdirections: {
          name: "Специализация",
          list: [],
          selected: [],
          code: "subdirections",
          follow: "directions",
        },
      },
      searchModel: "",

      projectsRaw: [],
      nProjectsRaw: [],

      sectorsList: [],
      sectionsList: [],

      pageSelected: 1,
      pageMaxItems: 9,

      userHasAttempts: false,

      showModal: false,

      projectsLoading: true,

      modalCreateProject: {
        opened: false,

        projectId: null,
        id: null,
        name: "",
        description: "",
        result: "",
        effect: "",
      },

      modalCreatedResult: false,

      showSkipButton: false,
    };
  },
  async created() {
    let userHasCGAttemptsResponse = await userHasAttempts(this.user.id);
    this.userHasAttempts = userHasCGAttemptsResponse.data.result;

    let sectorsListResponse = await getSectorsWithSections();
    this.sectorsList = sectorsListResponse.data;

    if (this.$route.query?.DirectionsSelected != null) {
      this.filters.directions.selected = this.$route.query?.DirectionsSelected.split(
        ","
      ).map((x) => x.replaceAll("$$$", "&"));
    }

    if (this.$route.query?.subDirectionsSelected != null) {
      this.filters.subdirections.selected = this.$route.query?.subDirectionsSelected
        .split(",")
        .map((x) => x.replaceAll("$$$", "&"));
    }

    this.filters.directions.list = this.sectorsList.map((x) => x.name);

    let nProjectsResponse = await getApprovedProjectsWithTasks();
    this.nProjectsRaw = nProjectsResponse.data;

    let projectsResponse = await getAllProject();
    this.projectsRaw = projectsResponse.data.filter((proj) => {
      if (proj?.name === "Свой проект") {
        return true;
      }

      if (proj?.Jobs?.length != 0 && proj.Jobs.every((job) => job.sectionId !== null)) {
        return true;
      }

      return false;
    });

    await this.getCreatedSelfTask();

    let _pinned_task_req = await getUserPinnedTask(this.user.id);
    if (_pinned_task_req.data?.["UserPinTask"]?.[0] == null) {
      this.showSkipButton = true;
    }

    setTimeout(() => {
      this.projectsLoading = false;
    }, 300);
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    sectionsNameFiltered() {
      if (this.filters.directions.selected.length == 0) {
        return [];
      } else {
        if (this.filters.subdirections.selected.length != 0) {
          return this.filters.subdirections.selected;
        }

        return this.sectionsNameFromFilters;
      }
    },
    sectionsNameFromFilters() {
      if (this.filters.directions.selected.length == 0) {
        return [];
      }

      let sectionsNames = this.filters.directions.selected
        .map((sector) => {
          let _sections_names_list = this.sectorsList.find(
            (_sector) => sector == _sector.name
          );

          if (_sections_names_list == null) {
            return null;
          }

          _sections_names_list = _sections_names_list.sections.map((x) => x.name);

          return _sections_names_list;
        })
        .filter((x) => x != null)
        .reduce((acc, val) => acc.concat(val), []);

      return sectionsNames;
    },

    projectsSorted() {
      return this.filterProjects(this.projectsRaw);
    },
    nProjectsSorted() {
      return this.filterProjects(this.nProjectsRaw);
    },

    getProjectsSorted() {
      let projects = this.projectsSorted;
      projects = projects.map((x) => {
        let havePriceJobs = this.jobsHavePayment(x.Jobs);
        return {
          ...x,
          project_type: "DefaultProject",
          project_have_prices: havePriceJobs,
        };
      });

      let nProjects = this.nProjectsSorted;
      nProjects = nProjects.map((x) => {
        let havePriceJobs = this.jobsHavePayment(x.Jobs);
        return {
          ...x,
          project_type: "NProject",
          project_have_prices: havePriceJobs,
        };
      });

      this.pageSelected = 1;

      let mergedProjects = [...projects, ...nProjects]
        .sort((a, b) => {
          //TODO: логику сортировки нужно переделать
          if (a.project_have_prices !== false && b.project_have_prices === false) {
            return -1;
          } else if (a.project_have_prices === false && b.project_have_prices !== false) {
            return 1;
          } else if (a.project_type === "NProject" && b.project_type !== "NProject") {
            return -1;
          } else if (a.project_type !== "NProject" && b.project_type === "NProject") {
            return 1;
          } else {
            return 0;
          }
        })
        .sort((a, b) => {
          let _a = a.name === "Свой проект" ? 1 : 0;
          let _b = b.name === "Свой проект" ? 1 : 0;

          return _b - _a;
        });

      if (this.searchModel !== "") {
        let result = [];

        // Опции для настройки поиска
        const options = {
          isCaseSensitive: false,
          includeScore: false,
          shouldSort: true,
          threshold: 0.65,
          keys: ["name"], // Поля, по которым будет производиться поиск
        };

        // Инициализация экземпляра fuse
        const fuse = new Fuse(mergedProjects, options);

        // Выполнение поиска
        result = fuse.search(this.searchModel);
        return result.map((x) => x.item);
      }

      return mergedProjects;
    },

    showCardsList() {
      return this.getPieceOfList(
        this.getProjectsSorted,
        this.pageMaxItems,
        this.pageSelected
      );
    },

    canCreateSelfTask() {
      return (
        this.modalCreateProject.name.length >= 5 &&
        this.modalCreateProject.description.length >= 15 &&
        this.modalCreateProject.effect.length >= 15 &&
        this.modalCreateProject.result.length >= 15
      );
    },
  },
  methods: {
    goBack() {
      if (window.history.state.back != null) {
        if (window.history.state.back.includes("/content/project-tasks")) {
          this.$router.push({ name: "BetaProfile" });
        } else {
          // Если условие не выполнено, идём назад
          this.$router.go(-1);
        }
      } else {
        this.$router.push({ name: "BetaProfile" }); // Замените 'HomePage' на имя вашего роута
      }
    },
    goToProfile() {
      this.$router.push({ name: "BetaProfile" });
    },

    filterProjects(project_list) {
      if (this.sectionsNameFiltered?.length === 0) {
        return project_list;
      }

      return project_list
        .map((project) => {
          let jobs = project.Jobs.filter((job) =>
            job?.sections.some((x) => this.sectionsNameFiltered.includes(x.name))
          );

          jobs = jobs == null ? [] : jobs;

          return { ...project, Jobs: jobs };
        })
        .filter((project) => project.Jobs.length > 0);
    },

    jobsHavePayment(project_jobs) {
      return project_jobs.some(
        (project_job) => project_job.price !== null && project_job.price !== 0
      );
    },

    TextWrapperTasksCounter(value) {
      let words = ["задач", "задача", "задачи"];

      value = Math.abs(value) % 100;
      var num = value % 10;

      if (value > 10 && value < 20) {
        return words[0];
      }

      if (num > 1 && num < 5) {
        return words[2];
      }

      if (num == 1) {
        return words[1];
      }

      return words[0];
    },

    GetProjectTasksDirectionsCounter(jobs_array) {
      let jobs_directions = jobs_array.map((x) => x.sections.map((i) => i.name)).flat();

      const uniqueElements = [...new Set(jobs_directions)];

      let wrapText = (length, text) => {
        return `${length} ${text}`;
      };

      let words = ["компетенций", "компетенция", "компетенции"];

      let value = uniqueElements.length;
      value = Math.abs(value) % 100;
      var num = value % 10;

      if (value > 10 && value < 20) {
        return wrapText(uniqueElements.length, words[0]);
      }

      if (num > 1 && num < 5) {
        return wrapText(uniqueElements.length, words[2]);
      }

      if (num == 1) {
        return wrapText(uniqueElements.length, words[1]);
      }

      return wrapText(uniqueElements.length, words[0]);
    },

    updateSearchInput(newValue) {
      this.searchModel = newValue;
    },

    updateSecondFiltersList() {
      let newArr = this.sectionsNameFromFilters;
      this.filters.subdirections.list = newArr;
      this.filters.subdirections.selected = this.filters.subdirections.selected.filter(
        (item) => newArr.includes(item)
      );
    },

    getPieceOfList(arr, count, page) {
      if (arr == null) {
        return [];
      }

      let start = count * (page - 1);
      return arr.slice(start, start + count);
    },

    updateSelectedPage(newVal) {
      this.pageSelected = newVal;
    },

    selectProject(type, id) {
      let filterSettings = [];

      if (this.filters.directions.selected.length !== 0) {
        filterSettings.push(
          `DirectionsSelected=${this.filters.directions.selected
            .map((x) => x.replaceAll("&", "$$$$$$"))
            .join(",")}`
        );
      }

      if (this.filters.subdirections.selected.length !== 0) {
        filterSettings.push(
          `subDirectionsSelected=${this.filters.subdirections.selected
            .map((x) => x.replaceAll("&", "$$$$$$"))
            .join(",")}`
        );
      }

      this.$router.push(
        `/content/project-tasks?type=${type}&id=${id}&${filterSettings.join("&")}`
      );
    },

    closeCreationModal() {
      this.modalCreateProject.opened = false;
    },

    async getCreatedSelfTask() {
      let selfProjectResponse = await findAllJobByUser(this.user.id);
      if (selfProjectResponse.data.length > 0) {
        const selfProject = selfProjectResponse.data[0];
        this.modalCreateProject.name = selfProject.name;
        this.modalCreateProject.description = selfProject.description;
        this.modalCreateProject.result = selfProject.result;
        this.modalCreateProject.effect = selfProject.effect;
        this.modalCreateProject.id = selfProject.id;
      }
    },
    async createSelfTask() {
      let data = {
        id: this.modalCreateProject.id,
        authorId: this.user.id,
        name: this.modalCreateProject.name,
        description: this.modalCreateProject.description,
        result: this.modalCreateProject.result,
        effect: this.modalCreateProject.effect,
        levels: [],
        sections: [],
        level: null,
        projectId: this.modalCreateProject.projectId,
        status: "moderation",
        order: 0,
      };

      if (this.modalCreateProject.id == null) {
        delete data.id;
      }

      let createJobResponse = await createJob(data);
      let createdJobId = createJobResponse?.data?.id || 0;

      await pinTask(createdJobId, { user_id: this.user.id });

      this.closeCreationModal();
      this.modalCreatedResult = true;
    },

    handleEditorEditChanges(delta, setTo, fromEditorName) {
      if (this.$refs[fromEditorName] == null) {
        return 0;
      }
      let htmlDataFromEditor = this.$refs[fromEditorName].getHTML();
      switch (setTo) {
        case "Result": {
          this.modalCreateProject.result = htmlDataFromEditor;
          break;
        }
        case "Effect": {
          this.modalCreateProject.effect = htmlDataFromEditor;
          break;
        }
        case "Description": {
          this.modalCreateProject.description = htmlDataFromEditor;
          break;
        }
      }
    },

    handleEditorEditReady(setTo, fromEditorName) {
      if (this.$refs[fromEditorName] == null) {
        return 0;
      }
      let dataToSet = "";
      switch (setTo) {
        case "Result": {
          dataToSet = this.modalCreateProject.result;
          break;
        }
        case "Effect": {
          dataToSet = this.modalCreateProject.effect;
          break;
        }
        case "Description": {
          dataToSet = this.modalCreateProject.description;
          break;
        }
      }
      this.$refs[fromEditorName].setHTML(dataToSet);
    },
  },
};
</script>

<style scoped>
.ButtonsContainer > * {
  margin: auto 0%;
}
.ButtonsContainer {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
}

.BackImage {
  position: relative;
  display: block;

  width: 16px;
  height: 16px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  rotate: -90deg;
}

.BackButton:not(.Disabled):active > .BackImage {
  filter: brightness(0) saturate(100%) invert(42%) sepia(50%) saturate(4871%)
    hue-rotate(224deg) brightness(103%) contrast(101%);
}

.BackButton.Disabled > .BackImage {
  filter: brightness(0) saturate(100%) invert(26%) sepia(53%) saturate(560%)
    hue-rotate(204deg) brightness(93%) contrast(84%);
}

.lds-dual-ring {
  position: absolute;
  z-index: 55555;
  align-items: flex-start;
  padding-top: 100px;
}
.lds-dual-ring-wrapper {
  position: absolute;
  z-index: 55554;

  width: 100%;
  height: 100%;
}

.TextWrapper {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;
}
.BGLight {
  position: absolute;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.453;

  background: url("./../../assets/img/ReDesign/pages/registration_pages/projects_list_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.ProjectsList {
  position: relative;

  display: flex;

  width: 100%;
  height: auto;
  min-height: 100svh;

  background-color: rgba(35, 38, 89, 1);
}
.ProjectsList > .BWPContainer {
  position: relative;

  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  padding: 50px 120px 32px 120px;

  width: 100%;
  max-width: 1920px;
  height: auto;
  min-height: 90svh;
}
.BWPContainer > *:not(.BGCollections) {
  z-index: 2;
}
.BGCollections {
  position: absolute;
  display: block;
  inset: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.Navbar {
  position: relative;
  display: block;

  width: 100%;
  height: 33px;

  background-color: rgba(0, 0, 0, 0.25);

  flex-shrink: 0;
}

.PLContainer {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.PLCHead {
  position: relative;
  display: flex;

  margin-bottom: 8px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}
.PLCHTitle {
  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;

  color: white;
}
.PLCHDesc {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;

  color: rgba(255, 255, 255, 0.6);
}

.PLCList {
  position: relative;
  display: grid;

  width: 100%;
  height: fit-content;

  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.LCard {
  position: relative;
  display: flex;

  padding: 20px;

  width: 100%;
  min-height: 248px;
  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;

  border-radius: var(--o-s-global-border-radius);

  background-color: rgba(41, 45, 104, 0.8);

  transition: 0.2s;

  cursor: pointer;
}
.LCard:hover {
  background-color: rgba(48, 53, 126, 0.8);
}

.CardTitle {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  color: white;
}
.LCBottom {
  position: relative;
  display: flex;

  margin-top: auto;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.AutoHyphens {
  hyphens: auto;
  word-break: break-all;
}

.InfoBlock {
  position: relative;
  display: block;

  padding: 8px 16px;

  width: fit-content;
  height: fit-content;

  border-radius: var(--o-s-global-border-radius);

  background-color: rgba(255, 255, 255, 0.1);
}

.IBText {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}

.TaskSelectWrapper {
  position: fixed;
  display: flex;

  inset: 0;

  width: 100%;
  height: 100svh;

  z-index: 700;

  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}
.TSModal {
  position: relative;
  display: flex;

  padding: 32px;
  margin: auto;

  width: fit-content;
  max-width: 95%;

  height: fit-content;
  max-height: 90svh;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;

  background-color: #232659;
  border-radius: var(--o-s-global-border-radius);

  overflow-x: hidden;
  overflow-y: auto;
}

.TSMHeader {
  position: relative;
  display: flex;

  min-width: 0;
  width: 450px;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.CDifficult {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.CDText {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  color: rgb(255, 255, 255);
}
.CloseModal {
  position: relative;
  display: block;

  cursor: pointer;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/close_icon_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  filter: brightness(0) saturate(100%) invert(35%) sepia(10%) saturate(1697%)
    hue-rotate(199deg) brightness(94%) contrast(83%);
}
.TextWrapper.TaskTitle {
  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;

  color: #ffffffe5;
}
.TextWrapper.TaskDesc {
  padding: 0;

  min-width: 0;
  width: 950px;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;

  color: #ffffff99;
}
.Buttons {
  position: relative;
  display: flex;

  min-width: 0;
  width: 450px;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.TSMInput {
  position: relative;
  display: block;

  padding: 14px 20px;

  min-width: 0;
  width: 450px;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  color: white;

  transition: 0.25s;

  outline: none;
  border: 1px solid #8f63f03d;
  border-radius: var(--o-s-global-border-radius);
  background-color: transparent;
}
.TSMInput:focus {
  border: 1px solid #8f63f0;
}
.PrimaryReStyle {
  height: auto;
}

.FinalModalText {
  position: relative;
  display: block;

  min-width: 0;
  max-width: 100%;
  width: 450px;

  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  color: #fff;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .ProjectsList > .BWPContainer {
    gap: 30px;
    padding: 20px 16px 32px 16px;
  }

  .PLCHTitle {
    font-size: 22px;
  }
  .PLCHDesc {
    font-size: 16px;
    line-height: 120%;
  }
  .CardTitle {
    font-size: 14px;
    line-height: 120%;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }

  .IBText {
    font-size: 13px;
  }

  .TSModal {
    padding: 16px;
  }

  .CDText {
    font-size: 16px;
  }

  .TSMInput {
    padding: 10px 16px;
    font-size: 14px;
  }

  .FinalModalText {
    font-size: 13px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .ProjectsList > .BWPContainer {
    gap: 30px;
    padding: 20px 16px 32px 16px;
  }

  .PLCHTitle {
    font-size: 22px;
  }
  .PLCHDesc {
    font-size: 16px;
    line-height: 120%;
  }
  .CardTitle {
    font-size: 14px;
    line-height: 120%;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }

  .IBText {
    font-size: 14px;
  }

  .TSModal {
    padding: 16px;
  }

  .CDText {
    font-size: 16px;
  }

  .TSMInput {
    padding: 10px 16px;
    font-size: 14px;
  }

  .FinalModalText {
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .ProjectsList > .BWPContainer {
    gap: 30px;
    padding: 20px 60px 32px 60px;
  }

  .PLCHTitle {
    font-size: 26px;
  }
  .PLCHDesc {
    font-size: 18px;
  }
  .CardTitle {
    font-size: 16px;
  }

  .PLCList {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .ProjectsList > .BWPContainer {
    gap: 30px;
    padding: 20px 60px 32px 60px;
  }

  .PLCHTitle {
    font-size: 26px;
  }
  .PLCHDesc {
    font-size: 18px;
  }
  .CardTitle {
    font-size: 16px;
  }

  .PLCList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .BGLight {
    position: fixed;
    height: 100%;
    background-position: center;
    background-size: 180%;
  }
}
</style>
